<template>
    <div class="page">
        <v-container fluid>
            <!-- TODO: Habilitar depois, com a visao do setor
            <v-row class="pt-4">
                <v-col lg="4" cols="sm" class="pb-2">
                    <v-card
                        class="mt-4 mx-auto"
                    >
                        <v-sheet
                        class="v-sheet--offset mx-auto"
                        color="terciary"
                        elevation="12"
                        max-width="calc(100% - 32px)"
                        >
                            <v-sparkline
                                :labels="processosAbertosLabels"
                                :value="processosAbertosData"
                                color="white"
                                line-width="2"
                                padding="16"
                            ></v-sparkline>
                        </v-sheet>

                        <v-card-text class="pt-0">
                            <div class="title font-weight-light mb-2">
                                Processos enviados
                            </div>
                            <div class="subheading font-weight-light grey--text">
                                Acompanhamento por mês
                            </div>
                            <v-divider class="my-2"></v-divider>
                            <v-icon
                                class="mr-2"
                                small
                            >
                                mdi-clock
                            </v-icon>
                            <span class="caption grey--text font-weight-light">última atualização há 30 minutos</span>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col lg="4" cols="sm" class="pb-2">
                    <v-card
                        class="mt-4 mx-auto"
                    >
                        <v-sheet
                            class="v-sheet--offset mx-auto"
                            color="terciary"
                            elevation="12"
                            max-width="calc(100% - 32px)"
                        >
                            <v-sparkline
                                :value="processosAbertosData"
                                :labels="processosAbertosLabels"
                                color="white"
                                line-width="2"
                                padding="16"
                            ></v-sparkline>
                        </v-sheet>
                        <v-card-text class="pt-0">
                            <div class="title font-weight-light mb-2">
                                Processos abertos
                            </div>
                            <div class="subheading font-weight-light grey--text">
                                Evolução de aberturas
                            </div>
                            <v-divider class="my-2"></v-divider>
                            <v-icon
                                class="mr-2"
                                small
                            >
                                mdi-clock
                            </v-icon>
                            <span class="caption grey--text font-weight-light">última atualização há 30 minutos</span>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col lg="4" cols="sm" class="pb-2">
                    <v-card
                        class="mt-4 mx-auto"
                    >
                        <v-sheet
                            class="v-sheet--offset mx-auto"
                            color="terciary"
                            elevation="12"
                            max-width="calc(100% - 32px)"
                        >
                            <v-sparkline
                                :labels="processosRecebidosLabels"
                                :value="processosRecebidosData"
                                color="white"
                                line-width="2"
                                padding="16"
                            ></v-sparkline>
                        </v-sheet>

                        <v-card-text class="pt-0">
                            <div class="title font-weight-light mb-2">
                                Processos recebidos
                            </div>
                            <div class="subheading font-weight-light grey--text">
                                Desde a abertura do sistema
                            </div>
                            <v-divider class="my-2"></v-divider>
                            <v-icon
                                class="mr-2"
                                small
                            >
                                mdi-clock
                            </v-icon>
                            <span class="caption grey--text font-weight-light">última atualização há 30 minutos</span>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            -->
            <v-row>
                <v-col cols="2">
                    <v-card title="Quantidade de documentos que necessitam de sua assinatura">
                    <v-list-item three-line
                        to="/assinatura/pendente">
                        <v-list-item-content>
                        <v-list-item-title class="headline mb-1 terciary--text">{{ documentosAAssinar }}</v-list-item-title>
                        <v-list-item-subtitle>Documentos à assinar</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-icon large color="black">mdi-draw</v-icon>
                    </v-list-item>
                    </v-card>
                </v-col>
                <v-col cols="2">
                    <v-card title="Número de notificações não lidas">
                    <v-list-item three-line
                        to="/processos/notificacoes">
                        <v-list-item-content>
                        <v-list-item-title class="headline mb-1 terciary--text">{{ qtdNotificacoes }}</v-list-item-title>
                        <v-list-item-subtitle>Notificações</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-icon large color="black">mdi-comment-plus-outline</v-icon>
                    </v-list-item>
                    </v-card>
                </v-col>
                <v-col cols="2">
                    <v-card title="Quantidade de processos à receber no seu(s) setor(es)">
                    <v-list-item three-line
                        to="/processos/receber">
                        <v-list-item-content>
                        <v-list-item-title class="headline mb-1 terciary--text">{{ processosAReceber }}</v-list-item-title>
                        <v-list-item-subtitle>Processos à receber</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-icon large color="black">mdi-file-edit-outline</v-icon>
                    </v-list-item>
                    </v-card>
                </v-col>
                <v-col cols="2">
                    <v-card title="Quantidade de processos atribuídos a você">
                    <v-list-item three-line
                        to="/processos/enviar">
                        <v-list-item-content>
                        <v-list-item-title class="headline mb-1 terciary--text">{{ processosAtribuidos }}</v-list-item-title>
                        <v-list-item-subtitle>Processos atribuídos</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-icon large color="black">mdi-file-check-outline</v-icon>
                    </v-list-item>
                    </v-card>
                </v-col>
                <v-col cols="2">
                    <v-card title="Número de tramitações com recebimento pendente">
                    <v-list-item three-line
                        to="/processos/recebimento/pendente">
                        <v-list-item-content>
                        <v-list-item-title class="headline mb-1 terciary--text">{{ processosRecebimentoPendente }}</v-list-item-title>
                        <v-list-item-subtitle>Tramitação pendente</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-icon large color="black">mdi-file-move-outline</v-icon>
                    </v-list-item>
                    </v-card>
                </v-col>
                <v-col cols="2">
                    <v-card title="Quantidade de processos em forma de rascunhos">
                    <v-list-item three-line
                        to="/processos/rascunhos">
                        <v-list-item-content>
                        <v-list-item-title class="headline mb-1 terciary--text">{{ qtdRascunhos }}</v-list-item-title>
                        <v-list-item-subtitle>Rascunhos</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-icon large color="black">mdi-file-document-edit-outline</v-icon>
                    </v-list-item>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <v-card class="dark">
                        <v-card-title class="font-weight-light text-truncate terciary--text">
                            Últimos recebidos no setor
                        </v-card-title>
                        <v-card-text>
                            <v-layout v-if="ultimosProcessosRecebidosState">
                                <v-carousel
                                    cycle
                                    height="180"
                                    interval="6000"
                                    hide-delimiter-background
                                    show-arrows-on-hover
                                >
                                    <v-carousel-item
                                        v-for="item in ultimosProcessosRecebidos" :key="item.index">
                                        <div class="row no-gutters">
                                            <div class="col">
                                                <div>
                                                    <h2 class="terciary--text">
                                                        Processo {{ item.processo.numero }}/{{ item.processo.ano }}
                                                        <i class="mdi mdi-34px mdi-file-edit-outline float-right"></i>
                                                    </h2>
                                                    <p class="mt-1">{{ item.processo.tipo_processo.nome }}</p>
                                                    <h3 class="mb-0">Origem: {{ item.setor_origem.nome }}</h3>
                                                    <br/>
                                                    <p class="mt-1">Recebido por {{ item.usuario.nome }}</p>
                                                    <p class="mt-1">Em {{ $moment(item.created_at).format('DD/MM/YYYY') }} às {{ $moment(item.created_at).format('HH:mm') }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </v-carousel-item>
                                </v-carousel>
                            </v-layout>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="4">
                    <v-card class="dark">
                        <v-card-title class="font-weight-light text-truncate terciary--text">
                            Processos com você
                        </v-card-title>
                        <v-card-text>
                            <v-layout v-if="ultimosProcessosAtribuidosState">
                                <v-carousel
                                    cycle
                                    height="180"
                                    interval="7000"
                                    hide-delimiter-background
                                    show-arrows-on-hover
                                >
                                    <v-carousel-item
                                        v-for="item in ultimosProcessosAtribuidos" :key="item.id">
                                        <div class="row no-gutters">
                                            <div class="col">
                                                <div>
                                                    <h2 class="terciary--text">Processo {{ item.numero }}/{{ item.ano }}<i class="mdi mdi-34px mdi-file-check-outline float-right"></i></h2>
                                                    <p class="mt-1">{{ item.tipo_processo.nome }}</p>
                                                    <h3 class="mb-0">Assunto: {{ item.assunto.nome }}</h3>
                                                    <br/>
                                                    <p class="mt-1">Setor atual: {{ item.setor_atual.nome }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </v-carousel-item>
                                </v-carousel>
                            </v-layout>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="4">
                    <v-card class="dark">
                        <v-card-title class="font-weight-light text-truncate terciary--text">
                            Últimos enviados pelo setor
                        </v-card-title>
                        <v-card-text>
                            <v-layout v-if="ultimosProcessosEnviadosState">
                                <v-carousel
                                    cycle
                                    height="180"
                                    interval="8000"
                                    hide-delimiter-background
                                    show-arrows-on-hover
                                >
                                    <v-carousel-item
                                        v-for="item in ultimosProcessosEnviados" :key="item.id">
                                        <div class="row no-gutters">
                                            <div class="col">
                                                <div>
                                                    <h2 class="terciary--text">
                                                        Processo {{ item.processo.numero }}/{{ item.processo.ano }}
                                                        <i class="mdi mdi-34px mdi-file-move-outline float-right"></i>
                                                    </h2>
                                                    <!-- <p class="mt-1">{{ item.processo.tipo_processo.nome }}</p>
                                                    <h3 class="mb-0">Destino: {{ item.setor_destino.nome }}</h3>
                                                    <br/>
                                                    <p class="mt-1">Enviado por {{ item.usuario.nome }}</p> -->
                                                    <p class="mt-1">Em {{ $moment(item.created_at).format('DD/MM/YYYY') }} às {{ $moment(item.created_at).format('HH:mm') }}
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </v-carousel-item>
                                </v-carousel>
                            </v-layout>
                        </v-card-text>
                    </v-card>
                </v-col>

                <!--
                <v-col lg="4" cols="md" class="pb-2">
                    <v-card min-height="252">
                        <v-card-title class="font-weight-light text-truncate terciary--text">
                            Validação de processos
                        </v-card-title>
                        <v-card-text>
                            <p class="terciary--text subtitle-1">
                                Últimas tramitações
                            </mdi-file-move-outline                            <div class="my-5">
                                <v-progress-linear
                                indeterminate
                                height="8"
                                color="terciary"
                                ></v-progress-linear>
                                <h6>DESDE ABRIL/2021</h6>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn outlined rounded color="primary">
                                Ver
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                -->
            </v-row>

            <!--<v-row>
                <v-col xl="8" lg="8" md="8" sm="8" xs="12">
                    <v-card height="350" class="pa-2" max-width="100%">
                        <apexchart v-if="showChartDocumentosMes" type="bar" height="100%" width="100%" :options="chartOptionsDocumentosMes" :series="seriesDocumentosMes"></apexchart>
                    </v-card>
                </v-col>
                <v-col xl="4" lg="4" md="4" sm="4" xs="12">
                    <v-card height="350" class="pa-2">
                        <apexchart v-if="showChartTwo" type="polarArea" height="100%" width="100%" :options="chartOptionsTwo" :series="seriesTwo"></apexchart>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card class="mx-auto text-center">
                        <v-card-title class="primary--text">
                            Quantidade de processos abertos no seu setor
                        </v-card-title>
                        <v-sparkline
                            :value="processosAbertosData"
                            padding="18"
                            label-size="4"
                            color="terciary"
                            :line-width="2"
                            :stroke-linecap="'round'"
                            smooth>
                            <template v-slot:label="item">
                                {{ item.value }}
                            </template>
                        </v-sparkline>
                    </v-card>
                    <v-card-actions class="py-4 justify-center">
                        <v-btn class="terciary" color="secondary" to="/reports">Ver relatórios</v-btn>
                    </v-card-actions>
                </v-col>
            </v-row>
        -->
        </v-container>
    </div>
</template>

<script>
export default {
    data () {
        return {
            seriesDocumentosMes: [{
                name: "Quantidade de documentos",
                data: []
            }],
            chartOptionsDocumentosMes: {
                title: {
                    text: "Quantidade de documentos enviados por mês",
                    align: "left",
                },
                annotations: {
                    xaxis: [{
                        borderColor: '#00E396',
                        label: {
                            borderColor: '#00E396',
                            style: {
                            color: '#fff',
                            background: '#00E396',
                            },
                            text: 'X annotation',
                        }
                    }],
                    yaxis: [{
                        y: 'July',
                        y2: 'September',
                        label: {
                            text: 'Y annotation'
                        }
                    }]
                },
                plotOptions: {
                    bar: {
                    horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: true
                },
                xaxis: {
                    categories: [],
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: true
                        }
                    }
                },
                yaxis: {
                    reversed: true,
                    axisTicks: {
                        show: true
                    }
                }
            },
            seriesTwo: [],
            chartOptionsTwo: {
                title: {
                    text: "Quantidade de documentos recebidos por tipo",
                    align: "left",
                },
                chart: {
                    width: 380,
                    type: 'polarArea'
                },
                labels: [],
                fill: {
                    opacity: 1
                },
                stroke: {
                    width: 1,
                    colors: undefined
                },
                yaxis: {
                    show: false
                },
                legend: {
                    position: 'bottom'
                },
                plotOptions: {
                    polarArea: {
                    rings: {
                        strokeWidth: 0
                    },
                    spokes: {
                        strokeWidth: 0
                    },
                    }
                },
                theme: {
                    monochrome: {
                    enabled: true,
                    shadeTo: 'light',
                    shadeIntensity: 0.6
                    }
                }
            },
            ultimosProcessosEnviados: [],
            ultimosProcessosEnviadosState: false,
            ultimosProcessosRecebidos: [],
            ultimosProcessosRecebidosState: false,
            ultimosProcessosAtribuidos: [],
            ultimosProcessosAtribuidosState: false,
            processosAReceber: 0,
            processosAtribuidos: 0,
            processosRecebimentoPendente: 0,
            processosEnviadosData: [0],
            processosEnviadosLabels: ['05/21'],
            processosAbertosData: [0],
            processosAbertosLabels: ['05/21'],
            processosRecebidosData: [0],
            processosRecebidosLabels: ['05/21'],
            qtdNotificacoes: 0,
            qtdRascunhos: 0,
            documentosAAssinar: 0,
            documentosEnviados: 0,
            documentosAbertos: 0,
            documentosRecebidos: 0,
            labels: [
                '12am',
                '3am',
                '6am',
                '9am',
                '12pm',
                '3pm',
                '6pm',
                '9pm',
            ],
            value: [
                200,
                675,
                410,
                390,
                310,
                460,
                250,
                240,
            ],
            showChartDocumentosMes: false,
            showChartTwo: false,
            pendingBlocks: {
                userCode: null,
            },
        }
    },
    methods: {

        getDocumentosAAssinar() {
            this.pendingBlocks.userCode = this.$store.state.usuario.email
            this.axios.post('subscription/blocks/pendingBlocks', this.pendingBlocks).then(({ data: { data } }) => {
                this.documentosAAssinar = data.length
            })
        },

        getProcessosAReceber() {
            this.axios.post(`processo/consulta/${this.$store.state.usuario.id}/receber`).then((res) => {
                this.processosAReceber = res.data.length
            })
        },

        getProcessosAtribuidos() {
            this.axios.get(`processo/consulta/${this.$store.state.usuario.id}/atribuicao`).then((res) => {
                this.processosAtribuidos                = res.data.length
                this.ultimosProcessosAtribuidos         = res.data
                this.ultimosProcessosAtribuidosState    = true
            })
        },

        getProcessosRecebimentoPendente() {
            this.axios.post(`processo/consulta/${this.$store.state.usuario.id}/recebimentopendente`).then((res) => {
                this.processosRecebimentoPendente = res.data.length
            })
        },

        getUltimosProcessosEnviados() {
            this.axios.get('processo/dashboard/setor/processoenviados').then((res) => {
                this.ultimosProcessosEnviados       = res.data
                this.ultimosProcessosEnviadosState  = true
            })
        },

        getUltimosProcessosRecebidos() {
            this.axios.get('processo/dashboard/setor/processorecebidos').then((res) => {
                this.ultimosProcessosRecebidos      = res.data
                this.ultimosProcessosRecebidosState = true
            })
        },

        getNotificacoes() {
            this.axios.get(`${process.env.VUE_APP_PROCESSO_URL}notificacao/${this.$store.state.usuario.id}?lido=0`).then((res) => {
                this.qtdNotificacoes    = res.data.length
            })
        },

        getRascunho() {
            this.axios.post(`processo/consulta/${this.$store.state.usuario.id}/rascunho`).then((res) => {
                this.qtdRascunhos       = res.data.length
            })
        },

        /*
        async getProcessosEnviados() {
            // const processosenviados = await this.axios.get('processo/dashboard/processoenviados')
            // for(let i = 0; i < processosenviados.data.length; i++) {
            //     this.processosEnviadosData.push(processosenviados.data[i].quantidade)
            //     this.processosEnviadosLabels.push(processosenviados.data[i].mes.toString() + '/' + processosenviados.data[i].ano_tramitacao.toString().substring(2, 4))
            //     this.documentosEnviados = processosenviados.data[i].quantidade + this.documentosEnviados;
            //     this.seriesDocumentosMes[0].data.push(processosenviados.data[i].quantidade)
            //     this.chartOptionsDocumentosMes.xaxis.categories.push(processosenviados.data[i].mes.toString() + '/' + processosenviados.data[i].ano_tramitacao.toString().substring(2, 4))
            // }
            this.showChartDocumentosMes = true;
        },

        async getProcessosAbertos() {
            const processosAbertos = await this.axios.get('processo/dashboard/processoabertos')
            for(let i = 0; i < processosAbertos.data.length; i++) {
                this.processosAbertosData.push(processosAbertos.data[i].quantidade)
                this.processosAbertosLabels.push(processosAbertos.data[i].mes.toString() + '/' + processosAbertos.data[i].ano.toString().substring(2, 4))
                this.documentosAbertos = processosAbertos.data[i].quantidade + this.documentosAbertos;
            }
        },
        async getProcessosRecebidos() {
            const processosrecebidos = await this.axios.get('processo/dashboard/processorecebidos')
            for(let i = 0; i < processosrecebidos.data.length; i++) {
                this.processosRecebidosData.push(processosrecebidos.data[i].quantidade)
                this.processosRecebidosLabels.push(processosrecebidos.data[i].mes.toString() + '/' + processosrecebidos.data[i].ano_tramitacao.toString().substring(2, 4))
                this.documentosRecebidos = processosrecebidos.data[i].quantidade + this.documentosRecebidos;
            }
        },
        async getProcessosAbertoSetor() {
            const processosabertosetor = await this.axios.get('processo/dashboard/processoabertosetor')
            this.processosabertosetor = processosabertosetor.data
        },
        async getProcessosAtribuidosUsuarios() {
            const processosatribuidosusuarios = await this.axios.get('processo/dashboard/processoatribuidousuario')
            this.processosatribuidosusuarios = processosatribuidosusuarios.data
        },
        */
    },
    mounted () {
        this.getRascunho()                      // Quantidade de processos como rascunho
        this.getDocumentosAAssinar()            // Quantidade de documentos a assinar
        this.getProcessosAReceber()             // Quantidade de processos a receber
        this.getProcessosAtribuidos()           // Processos atribuidos - quantidade e carrosel
        this.getProcessosRecebimentoPendente()  // Quantidade de processos com recebimento pendente
        this.getUltimosProcessosEnviados()      // Carrosel com os ultimos processos enviados pelo setor
        this.getUltimosProcessosRecebidos()     // Carrosel com os ultimos processos recebidos no setor
        this.getNotificacoes()                  // Quantidade de notificacoes nao lidas
        setTimeout(1000)
        /*
        this.getProcessosEnviados()
        this.getProcessosAbertos()
        this.getProcessosRecebidos()
        this.getProcessosAbertoSetor()
        this.getProcessosAtribuidosUsuarios()
        */
    }
}
</script>
<style>
.v-sheet--offset {
    top: -24px;
    position: relative;
}
</style>